<div
    *ngIf="showAlert && !!alertData"
    class="alert-bar-wrapper"
    [ngClass]="{ 'bar-border': alertData?.classes }"
    [ngStyle]="{
        'background-color': windowSize.isSmallDevice
            ? alertData.mobile_background_color
            : alertData.background_color
    }"
>
    <div class="container {{ alertData?.classes }}">
        <div class="alert-bar" [ngClass]="{ 'flex-column': alertData?.title }">
            <div *ngIf="alertData?.title" class="message d-block title">
                <h3 [ngStyle]="{ color: alertData.text_color }">{{ alertData?.title }}</h3>
            </div>
            <!--            TODO: rework alert bat for iframe-->
            <div *ngIf="alertData.text" class="message d-block text">
                <span [ngStyle]="{ color: alertData.text_color }">{{ alertData.text }}</span>
                <a
                    [ngStyle]="{ color: alertData.text_color }"
                    [href]="alertData.link"
                    [target]="alertData?.link_target"
                    *ngIf="alertData?.classes"
                >
                    {{ alertData.call_to_action }}
                    <app-icon [type]="'Arrow-right'"></app-icon>
                </a>
            </div>
            <div
                *ngIf="alertData.link && alertData.call_to_action && !alertData?.classes"
                class="link"
            >
                <a [ngStyle]="{ color: alertData.text_color }" [href]="alertData.link">
                    {{ alertData.call_to_action }}
                    <app-icon [type]="'Arrow-right'"></app-icon>
                </a>
            </div>
            <div class="close-alert" (click)="close()">
                <app-icon
                    [type]="'Close'"
                    *ngIf="!alertData?.classes"
                    [ngStyle]="{ color: alertData.text_color }"
                ></app-icon>
                <app-icon
                    [type]="'ThinCross'"
                    [ngStyle]="{ color: alertData.text_color }"
                    *ngIf="alertData?.classes"
                ></app-icon>
            </div>
        </div>
    </div>
</div>
