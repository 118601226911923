import { GradeBand } from "./gradeBand.model"

export enum UserInputEvents {
    ENTER = "Enter",
    CLICK = "click",
}
export const PBSLM_TITLE = "PBS LearningMedia"

export const FORM_STACK_SUPPORT_URL =
    "https://pbs-cjoef.formstack.com/forms/pbs_learningmedia_profile"

export enum SubjectEnum {
    Science = "Science",
    SocialStudies = "Social Studies",
    Mathematics = "Mathematics",
    EnglishLanguageArts = "English Language Arts",
    EngineeringTechnology = "Engineering & Technology",
    HealthPhysicalEducation = "Health & Physical Education",
    Preschool = "Preschool",
    ProfessionalDevelopment = "Professional Development",
    TheArts = "The Arts",
    WorldLanguages = "World Languages",
    NA = "N/A",
}

export enum GradeBandEnum {
    BandPreKtoK = "PreK-K",
    BandKto2 = "K-2",
    Band3to5 = "3-5",
    Band6to8 = "6-8",
    Band9to12 = "9-12",
    Band13Plus = "13+",
}

export const DashboardMenuPagesWhiteList: string[] = [
    "/dashboard/",
    "tools/lessonbuilder",
    "tools/",
    "tools/assignments",
    "favorites",
    "folders",
]

export const studentNounList: string[] = [
    "Analyzing",
    "Arriving",
    "Biking",
    "Breaking",
    "Catching",
    "Celebrating",
    "Chasing",
    "Climbing",
    "Coloring",
    "Composing",
    "Computing",
    "Cooking",
    "Crawling",
    "Dancing",
    "Deciphering",
    "Decoding",
    "Detecting",
    "Diving",
    "Drawing",
    "Eating",
    "Encoding",
    "Examining",
    "Falling",
    "Fighting",
    "Flexing",
    "Floating",
    "Flying",
    "Going",
    "Growling",
    "Hiking",
    "Hunting",
    "Infiltrating",
    "Investigating",
    "Jetsetting",
    "Jumping",
    "Landing",
    "Launching",
    "Leaving",
    "Outsmarting",
    "Painting",
    "Peeking",
    "Racing",
    "Reading",
    "Riding",
    "Rising",
    "Rolling",
    "Rowing",
    "Running",
    "Sailing",
    "Sewing",
    "Shopping",
    "Singing",
    "Skating",
    "Skiing",
    "Skydiving",
    "Sledding",
    "Sleeping",
    "Sliding",
    "Sneaking",
    "Solving",
    "Standing",
    "Studying",
    "Surfing",
    "Surviving",
    "Swimming",
    "Tasting",
    "Testing",
    "Twirling",
    "Unlocking",
    "Watching",
    "Winning",
    "Wrestling",
    "Writing",
    "Able",
    "Adept",
    "Adorable",
    "Amazing",
    "Artistic",
    "Astonishing",
    "Athletic",
    "Awesome",
    "Big",
    "Bold",
    "Bookish",
    "Brainy",
    "Brilliant",
    "Bubbly",
    "Cagey",
    "Careful",
    "Caring",
    "Clever",
    "Conniving",
    "Cool",
    "Crazy",
    "Creative",
    "Cute",
    "Dainty",
    "Delightful",
    "Devious",
    "Enormous",
    "Epic",
    "Evil",
    "Extreme",
    "Fabuloso",
    "Fantastic",
    "Festive",
    "Fiery",
    "Friendly",
    "Funny",
    "Gigantic",
    "Glamorous",
    "Good",
    "Goofy",
    "Hilarious",
    "Hungry",
    "Illustrious",
    "Indescribable",
    "Inky",
    "Iridescent",
    "Irrestistable",
    "Kind",
    "Lazy",
    "Lone",
    "Loud",
    "Magical",
    "Mighty",
    "Modest",
    "Nimble",
    "Phenomenal",
    "Powerful",
    "Quiet",
    "Random",
    "Safe",
    "Secret",
    "Shrewd",
    "Silly",
    "Sleepy",
    "Sly",
    "Smart",
    "Sneaky",
    "Sparkly",
    "Sporty",
    "Spunky",
    "Strong",
    "Super",
    "Sweet",
    "Talented",
    "Thoughtful",
    "Trendy",
    "Twinkling",
    "Unbelievable",
    "Unique",
    "Wise",
    "Witty",
    "Amber",
    "Amethyst",
    "Aqua",
    "Azure",
    "Beige",
    "Black",
    "Blue",
    "Brown",
    "Chartreuse",
    "Crimson",
    "Dark",
    "Emerald",
    "Fuschia",
    "Gray",
    "Green",
    "Indigo",
    "Ivory",
    "Jade",
    "Lavender",
    "Light",
    "Magenta",
    "Maroon",
    "Onyx",
    "Opal",
    "Orange",
    "Periwinkle",
    "Pink",
    "Platinum",
    "Purple",
    "Red",
    "Ruby",
    "Saphhire",
    "Scarlet",
    "Silver",
    "Teal",
    "Topaz",
    "Turquoise",
    "Violet",
    "White",
    "Yellow",
    "Aggressive",
    "Amused",
    "Angry",
    "Arrogant",
    "Bashful",
    "Beastly",
    "Befuddled",
    "Bemused",
    "Bored",
    "Calm",
    "Cautious",
    "Chatty",
    "Cheerful",
    "Cheery",
    "Confident",
    "Confused",
    "Delighted",
    "Determined",
    "Disappointed",
    "Disgusted",
    "Eager",
    "Easygoing",
    "Energetic",
    "Enthusiastic",
    "Excited",
    "Fearless",
    "Giddy",
    "Giggly",
    "Glad",
    "Gloomy",
    "Grateful",
    "Happy",
    "Hopeful",
    "Joyful",
    "Joyous",
    "Lively",
    "Lolsome",
    "Lonesome",
    "Lonely",
    "Mad",
    "Melancholy",
    "Mellow",
    "Merry",
    "Mischievous",
    "Monkeyish",
    "Nervous",
    "Optimistic",
    "Peaceful",
    "Pessimistic",
    "Proud",
    "Psyched",
    "Regretful",
    "Rueful",
    "Sad",
    "Shocked",
    "Shy",
    "Spirited",
    "Surprised",
    "Thankful",
    "Thrilled",
    "Tranquil",
    "Vexed",
    "Vivacious",
    "Zealous",
    "Afternoon",
    "Autumn",
    "Dawn",
    "Dreamtime",
    "Dusk",
    "Early",
    "Late",
    "Midnight",
    "Midsummer",
    "Midwinter",
    "Moonlight",
    "Moonrise",
    "Morning",
    "Night",
    "Spring",
    "Summer",
    "Sunrise",
    "Sunset",
    "Twilight",
    "Winter",
]

export const studentAdjectiveList = [
    "Acrobatics",
    "Adventure",
    "Art",
    "Astronomy",
    "Band",
    "Baseball",
    "Basketball",
    "Club",
    "Drama",
    "Fashion",
    "Celebration",
    "Chess",
    "Chorus",
    "Dance",
    "Football",
    "Game",
    "Guitar",
    "Gymnastics",
    "Hockey",
    "Journey",
    "Magic",
    "Math",
    "Music",
    "Mystery",
    "Party",
    "Poetry",
    "Pursuit",
    "Quest",
    "Science",
    "Soccer",
    "Softball",
    "Sports",
    "Study",
    "Surprise",
    "Technology",
    "Travel",
    "Trickery",
    "Trumpet",
    "Volleyball",
    "Yoga",
    "Bear",
    "Bee",
    "Beetle",
    "Bird",
    "Bloodhound",
    "Bull",
    "Bulldog",
    "Butterfly",
    "Cat",
    "Chicken",
    "Chihuahua",
    "Cobra",
    "Cow",
    "Cricket",
    "Crow",
    "Dinosaur",
    "Dog",
    "Dolphin",
    "Dragon",
    "Dragonfly",
    "Eagle",
    "Fly",
    "Gerbil",
    "Goat",
    "Gopher",
    "Gorilla",
    "Grasshopper",
    "Hamster",
    "Hawk",
    "Hippo",
    "Horse",
    "Hound",
    "Kangaroo",
    "Kitten",
    "KoalaBear",
    "Koi",
    "Labrador",
    "Ladybug",
    "Lamb",
    "Lion",
    "Lizard",
    "Llama",
    "Lynx",
    "Mink",
    "Monkey",
    "Moth",
    "Mouse",
    "Panda",
    "Panther",
    "Parakeet",
    "Penguin",
    "Pig",
    "Piglet",
    "PolarBear",
    "Pony",
    "Puffin",
    "Pug",
    "Puma",
    "Puppy",
    "Rabbit",
    "Raccoon",
    "Rattlesnake",
    "Raven",
    "Robin",
    "Rooster",
    "Salmon",
    "Seal",
    "Shark",
    "Sheep",
    "Snake",
    "Snapper",
    "Sparrow",
    "Spider",
    "Tiger",
    "Tuna",
    "Turkey",
    "Turtle",
    "Wallabee",
    "Warthog",
    "Whale",
    "Wolf",
    "Worm",
    "Antennae",
    "Arms",
    "Beak",
    "Brain",
    "Claws",
    "Elbow",
    "Eyes",
    "Fangs",
    "Feathers",
    "Fin",
    "Foot",
    "Fur",
    "Gills",
    "Hands",
    "Heart",
    "Jaw",
    "Knees",
    "Leg",
    "Mind",
    "Nose",
    "Paw",
    "Scales",
    "shell",
    "Skin",
    "Snout",
    "Talons",
    "Teeth",
    "Toes",
    "Flippers",
    "Tummy",
    "Whiskers",
    "Wings",
    "Apple",
    "Bagel",
    "Banana",
    "Brownie",
    "Cake",
    "Candy",
    "Caramel",
    "Cheese",
    "Cheeseburger",
    "Cherries",
    "Chicken",
    "Chocolate",
    "Coffee",
    "Cupcake",
    "Donut",
    "Eggs",
    "Grapes",
    "Ham",
    "Hamburger",
    "Honeydew",
    "Hotdog",
    "Hummus",
    "Icecream",
    "Ketchup",
    "Kiwi",
    "Lemon",
    "Lemonade",
    "Lime",
    "Melon",
    "Mustard",
    "Nectar",
    "Peach",
    "Pear",
    "Pie",
    "Pinapple",
    "Pistachio",
    "Pizza",
    "Plum",
    "Porkchop",
    "Potatochips",
    "Spaghetti",
    "Sprinkles",
    "Steak",
    "Strawberry",
    "Sushi",
    "Tea",
    "Vanilla",
    "Waffle",
    "Watermelon",
    "Angel",
    "Basilisk",
    "Boggart",
    "Centaur",
    "Cerberus",
    "Chimera",
    "Cyclops",
    "Dragon",
    "Elf",
    "Fairy",
    "Ghost",
    "Gnome",
    "Goblin",
    "Gorgon",
    "Griffin",
    "Hydra",
    "Imp",
    "Kraken",
    "Leprechaun",
    "Medusa",
    "Mermaid",
    "Merman",
    "Minotaur",
    "Monster",
    "Mummy",
    "Pegasus",
    "Wizard",
    "Phoenix",
    "Pixie",
    "Satyr",
    "Selkie",
    "Sphinx",
    "Sprite",
    "Sylph",
    "Troll",
    "Unicorn",
    "Vampire",
    "Werewolf",
    "Witch",
    "Zombie",
    "Anubis",
    "Aphrodite",
    "Apollo",
    "Ares",
    "Artemis",
    "Athena",
    "Bast",
    "Cupid",
    "Demeter",
    "Freya",
    "Hathor",
    "Hephaestus",
    "Hera",
    "Hermes",
    "Horus",
    "Ishtar",
    "Juno",
    "Loki",
    "Merlin",
    "Nephthys",
    "Odin",
    "Osiris",
    "Poseidon",
    "Sekhmet",
    "Serqet",
    "Set",
    "Sobek",
    "Thor",
    "Thoth",
    "Venus",
    "Vulcan",
    "Zeus",
    "Actor",
    "Admiral",
    "Agent",
    "Ambassador",
    "Astronaut",
    "Athlete",
    "Author",
    "Believer",
    "Bookworm",
    "Boy",
    "Brigadier",
    "Captain",
    "Challenger",
    "Chief",
    "Coach",
    "Collector",
    "Colonel",
    "Commander",
    "Corporal",
    "Dancer",
    "Detective",
    "Diplomat",
    "Director",
    "Dodger",
    "Dreamer",
    "Duchess",
    "Duke",
    "Expert",
    "Explorer",
    "Fashionista",
    "Forager",
    "General",
    "Genius",
    "Girl",
    "Guard",
    "Hero",
    "Heroine",
    "Hunter",
    "Inventor",
    "Investigator",
    "Junior",
    "King",
    "Lieutenant",
    "Musician",
    "Negotiator",
    "Ninja",
    "Nomad",
    "Officer",
    "Peacemaker",
    "President",
    "Prince",
    "Princess",
    "Queen",
    "Racer",
    "Raider",
    "Rascal",
    "Reader",
    "Rider",
    "Rogue",
    "Ruler",
    "Scientist",
    "Scrounger",
    "Searcher",
    "Seeker",
    "Senior",
    "Skater",
    "Sleeper",
    "Soldier",
    "Specialist",
    "Spy",
    "Stranger",
    "Surfer",
    "Surgeon",
    "Survivor",
    "Teacher",
    "Thief",
    "Trickster",
    "Warrior",
    "Winner",
    "Wrestler",
    "Writer",
    "Airport",
    "Arcade",
    "Bastion",
    "Capital",
    "Castle",
    "Chateau",
    "Citadel",
    "City",
    "Club",
    "Continent",
    "Country",
    "Creek",
    "Desert",
    "Field",
    "Forest",
    "Galaxy",
    "Hideout",
    "Hotel",
    "House",
    "Island",
    "Jungle",
    "Library",
    "Maze",
    "Meadow",
    "Metropolis",
    "Moon",
    "Museum",
    "Ocean",
    "Plains",
    "Sea",
    "Ship",
    "Shore",
    "Sky",
    "Skyscraper",
    "Station",
    "Street",
    "Stronghold",
    "Swamp",
    "Treehouse",
    "Underground",
    "Volcano",
    "Arrow",
    "Book",
    "Card",
    "Cash",
    "Clue",
    "Code",
    "Coin",
    "Compass",
    "Constellation",
    "Crest",
    "Crown",
    "Electricity",
    "Fan",
    "Fire",
    "Flash",
    "Game",
    "Hint",
    "History",
    "Ink",
    "Ipod",
    "Iron",
    "Key",
    "Kite",
    "Laptop",
    "Lock",
    "Map",
    "Medal",
    "Mystery",
    "Necklace",
    "Phone",
    "Prism",
    "Puppet",
    "Sand",
    "Song",
    "Saga",
    "Steel",
    "Stone",
    "Surprise",
    "Team",
    "Tide",
    "Tree",
    "Trick",
    "Wave",
    "Words",
    "Blizzard",
    "Breeze",
    "Cloud",
    "Cloudburst",
    "Cyclone",
    "Downpour",
    "Fog",
    "Freeze",
    "Frost",
    "Gale",
    "Gloom",
    "Gust",
    "Hail",
    "Haze",
    "Heatwave",
    "Hurricane",
    "Ice",
    "Lightning",
    "Maelstrom",
    "Mist",
    "Monsoon",
    "Nebula",
    "Pressure",
    "Rain",
    "Rainbow",
    "Snow",
    "Squall",
    "Steam",
    "Storm",
    "Sunshine",
    "Tempest",
    "Thunderstorm",
    "Tornado",
    "Tsunami",
    "Twister",
    "Vapor",
    "Wave",
]

export const studentSecretQuestionOptions = [
    { id: 1, name: "What is your favorite animal?" },
    { id: 2, name: "What is your favorite color?" },
    { id: 3, name: "What is your favorite food?" },
    { id: 4, name: "What is your favorite game?" },
    { id: 5, name: "What is your best friend's name?" },
    { id: 6, name: "What is your favorite toy?" },
]

export const ORDERED_GRADE_KEYS: GradeBandEnum[] = [
    GradeBandEnum.BandPreKtoK,
    GradeBandEnum.BandKto2,
    GradeBandEnum.Band3to5,
    GradeBandEnum.Band6to8,
    GradeBandEnum.Band9to12,
]

export const ORDERED_GRADE_IMAGES: string[] = [
    "https://static.pbslearningmedia.org/static/assets/student-gate/prek_physical-development.png",
    "https://static.pbslearningmedia.org/static/assets/student-gate/science_life.png",
    "https://static.pbslearningmedia.org/static/assets/student-gate/social-studies_world-history.png",
    "https://static.pbslearningmedia.org/static/assets/student-gate/engineering-and-tech_history.png",
    "https://static.pbslearningmedia.org/static/assets/student-gate/math_HS-Functions.png",
]

export const resourcesToOpenInNewTab: string[] = ["Interactive Lesson", "Document", "Interactive"]

export const GRADE_BANDS: GradeBand[] = [
    {
        gtmLabel: GradeBandEnum.BandPreKtoK,
        label: GradeBandEnum.BandPreKtoK,
        uri: GradeBandEnum.BandPreKtoK,
        description:
            "Find early learning resources for preschool and kindergarten that support the critical transition to school.  Inspire your students with videos, games, and activities aligned to state and national standards.",
        metaTitle: "Early Learning Resources for PreK and K",
    },
    {
        gtmLabel: GradeBandEnum.BandKto2,
        label: GradeBandEnum.BandKto2,
        uri: GradeBandEnum.BandKto2,
        description:
            "Find early elementary resources and lessons. Inspire your students with videos, games, and activities aligned to state and national standards.",
        metaTitle: "Elementary Resources For K to 2",
    },
    {
        gtmLabel: GradeBandEnum.Band3to5,
        label: GradeBandEnum.Band3to5,
        uri: GradeBandEnum.Band3to5,
        description:
            "Find elementary resources and lessons. Inspire your students with videos, games, and activities aligned to state and national standards.",
        metaTitle: "Elementary Resources For 3 to 5",
    },
    {
        gtmLabel: GradeBandEnum.Band6to8,
        label: GradeBandEnum.Band6to8,
        uri: GradeBandEnum.Band6to8,
        description:
            "Find resources for middle school learners. Inspire your students with videos, games, and activities aligned to state and national standards.",
        metaTitle: "Resources For Middle School Lesson Plans",
    },
    {
        gtmLabel: GradeBandEnum.Band9to12,
        label: GradeBandEnum.Band9to12,
        uri: GradeBandEnum.Band9to12,
        description:
            "Find resources and activities for high school learners. Inspire your students with videos, games, and activities aligned to state and national standards.",
        metaTitle: "Resources For High School Lesson Plans",
    },
]

export const DeprecatedGradeURImap = {
    prek: GradeBandEnum.BandPreKtoK,
    "early-elementary": GradeBandEnum.BandKto2,
    "upper-elementary": GradeBandEnum.Band3to5,
    "middle-school": GradeBandEnum.Band6to8,
    "high-school": GradeBandEnum.Band9to12,
}

export const ORDERED_MEDIA_TYPES: string[] = [
    "Video",
    "Interactive Lesson",
    "Interactive",
    "Lesson Plan",
    "Media Gallery",
    "Audio",
    "Image",
    "Document",
    "Webpage",
    "Collection",
]

export const STUDENT_ORDERED_MEDIA_TYPES: string[] = [
    "Video",
    "Interactive",
    "Media Gallery",
    "Audio",
    "Image",
    "Document",
    "Webpage",
    "Collection",
]

export const SEARCH_FILTERS: string[] = [
    "Collection",
    "Video",
    "Interactive Lesson",
    "Interactive",
    "Lesson Plan",
    "Media Gallery",
    "Audio",
    "Image",
    "Document",
    "Webpage",
]

export const ADD_ON_ALLOWED_MEDIA_TYPES: string[] = [
    "Video",
    "Interactive Lesson",
    "Interactive",
    "Media Gallery",
    "Audio",
    "Image",
    "Document",
    "Webpage",
]

export const MEDIA_DROPDOWN_FILTERS: string[] = [
    "Video",
    "Interactive",
    "Media Gallery",
    "Audio",
    "Image",
    "Document",
    "Webpage",
]

export const STUDENT_GATE_FILTERED_PAGES: string[] = [
    "/resource/",
    "/subjects/",
    "/search",
    "/student",
    "/collection/",
]
export const STUDENT_GATE_INTERCEPTOR_WHITELIST: string[] = [
    "/api/v2/resource/",
    "/api/v2/lm/homepage/subject_collections/",
    "/api/v2/student-popular-resources/",
]

export const STUDENT_GATE_COLLECTION_WHITELIST: string[] = [
    "/api/v2/collection/resources/",
    "/api/v2/collection/res_info/",
    "/api/v2/collection/data/",
    "/api/v2/collection/premium/",
]

export const STATIC_IMAGES = {
    error_401: "https://static.pbslearningmedia.org/static/assets/static-images/401error.png",
    error_404: "https://static.pbslearningmedia.org/static/assets/static-images/404error.png",
    error_500: "https://static.pbslearningmedia.org/static/assets/static-images/500error.png",
    auth_google: "https://static.pbslearningmedia.org/static/assets/static-images/auth-google.svg",
    btn_remind_share_light_round_normal_2x:
        "https://static.pbslearningmedia.org/static/assets/static-images/btn_remind_share_light_round_normal_2x.png",
    classroom_color_round:
        "https://static.pbslearningmedia.org/static/assets/static-images/classroom-color-round.png",
    default_subcollection_banner:
        "https://static.pbslearningmedia.org/static/assets/static-images/default_subcollection_banner.png",
    google_classroom_gray_stroke_icon:
        "https://static.pbslearningmedia.org/static/assets/static-images/google_classroom_grey_stroke_icon.png",
    schoology_icon: "https://static.pbslearningmedia.org/static/assets/static-images/schoology.png",
    google_classroom_x48_yellow_stroke_icon:
        "https://static.pbslearningmedia.org/static/assets/static-images/google_classroom_x48_yellow_stroke_icon.png",
    lost_in_space:
        "https://static.pbslearningmedia.org/static/assets/static-images/Lost-in-Space.png",
    on_the_go: "https://static.pbslearningmedia.org/static/assets/static-images/On-the-go.png",
    pbslm_logo: "https://static.pbslearningmedia.org/static/assets/static-images/pbslm-logo.svg",
    pbslm_logo_open_area_inverse:
        "https://static.pbslearningmedia.org/static/assets/static-images/PBSLM-Logo-Open-Area-Inverse.svg",
    pbslm_logo_open_area:
        "https://static.pbslearningmedia.org/static/assets/static-images/PBSLM-Logo-Open-Area.svg",
    pbslm_logo_white:
        "https://static.pbslearningmedia.org/static/assets/static-images/pbslm-logo-white.svg",
    pbs_logo: "https://static.pbslearningmedia.org/static/assets/static-images/PBS-logo.svg",
    wgbh_gray: "https://static.pbslearningmedia.org/defaults/wgbh_white.png",
    singup_heading: "https://static.pbslearningmedia.org/defaults/signup_heading.png",
    pbs_classroom_ready_resources:
        "https://static.pbslearningmedia.org/defaults/pbs-classroom-ready-resources.jpg",
    teacher_and_student_classroom:
        "https://static.pbslearningmedia.org/defaults/teacher-and-excited-student-engaging-in-classroom.jpg",
    pbs_logo_white:
        "https://static.pbslearningmedia.org/static/assets/static-images/PBS-logo-white.svg",
    pbs_kids_pink: "https://static.pbslearningmedia.org/static/assets/pbs-kids-pink.png",
    pbs_kids_2023_logo:
        "https://static.pbslearningmedia.org/static/assets/static-images/pbs-kids-new-2023-logo.svg",
    pbs_logo_white_with_text:
        "https://static.pbslearningmedia.org/static/assets/static-images/PBS-logo-white-with-text.svg",
    placeholder_image:
        "https://static.pbslearningmedia.org/static/assets/static-images/placeholder-image.svg",
    student_site_hero:
        "https://static.pbslearningmedia.org/static/assets/static-images/Student_siteHero.jpg",
    teacher_student_error_page:
        "https://static.pbslearningmedia.org/static/assets/static-images/teacher_student_error_page.png",
    student_teacher_laptop:
        "https://static.pbslearningmedia.org/static/assets/static-images/Student-Teacher-Laptop.png",
    teacher_and_students:
        "https://static.pbslearningmedia.org/static/assets/static-images/teacher-and-students.png",
    teacher_small:
        "https://static.pbslearningmedia.org/static/assets/static-images/teacher-small.jpg",
    teacher: "https://static.pbslearningmedia.org/static/assets/static-images/teacher.jpg",
    default_student_hero:
        "https://static.pbslearningmedia.org/static/assets/static-images/Student_siteHero.jpg",
    newsletter_poster: "https://static.pbslearningmedia.org/static/assets/newsletter-poster.svg",
    newsletter_cover:
        "https://static.pbslearningmedia.org/static/assets/static-images/about/about-hero.png",
    STUDENT: {
        arrow_down:
            "https://static.pbslearningmedia.org/static/assets/static-images/student/arrow-down.png",
        check: "https://static.pbslearningmedia.org/static/assets/static-images/student/check.svg",
        corner:
            "https://static.pbslearningmedia.org/static/assets/static-images/student/corner.svg",
        curved_arrow_down:
            "https://static.pbslearningmedia.org/static/assets/static-images/student/curved-down-arrow.svg",
        icon_updown_op1:
            "https://static.pbslearningmedia.org/static/assets/static-images/student/icn-updown_op1.png",
        stuff: "https://static.pbslearningmedia.org/static/assets/static-images/student/stuff.svg",
    },
    ABOUT: {
        icon_security:
            "https://static.pbslearningmedia.org/static/assets/static-images/about/Icon-Security.svg",
        icon_filter:
            "https://static.pbslearningmedia.org/static/assets/static-images/about/Icon-Filter.svg",
        icon_reach:
            "https://static.pbslearningmedia.org/static/assets/static-images/about/Icon-Reach.svg",
        icon_standards:
            "https://static.pbslearningmedia.org/static/assets/static-images/about/Icon-Standards.svg",
        feature_icon_collections:
            "https://static.pbslearningmedia.org/static/assets/static-images/about/Feature-Icon-Collections.svg",
        feature_icon_favorites:
            "https://static.pbslearningmedia.org/static/assets/static-images/about/Feature-Icon-Favorites.svg",
        feature_icon_integrations:
            "https://static.pbslearningmedia.org/static/assets/static-images/about/Feature-Icon-Integrations.svg",
        feature_icon_lesson_builder:
            "https://static.pbslearningmedia.org/static/assets/static-images/about/Feature-Icon-LessonBuilder.svg",
    },
}

export const ALIASED_FACETS: string[] = ["cp", "cs", "ct", "subject"]

export const CH_FACET_ID = "ch"

export enum DrawerTabTitle {
    MyFavorites = 1,
    EntireSite,
    Upload,
    Instructions,
}

export enum BrowseByViews {
    Initial = 1,
    Subjects,
    Grades,
    Discipline,
}

export const CUSTOM_TABLE_STYLES = {
    centeredHeaderStyle: {
        justifyContent: "center",
    },
    alignCenter: {
        textAlign: "center",
        marginLeft: "-5px",
    },
    textBluePrimary: {
        color: "#2638c4",
        fontWeight: "bold",
    },
}
