<div class="row-auto g-0" [@.disabled]="isAnimationDisabled">
    <div *ngIf="activeViewId === VIEWS.Initial" class="view" @slideInLeft>
        <div class="item" (click)="setActiveView(VIEWS.Subjects); $event.stopPropagation()">
            <span class="item-title">Subjects</span>
            <div class="arrow-right">
                <app-icon [type]="'Arrow-right'"></app-icon>
            </div>
        </div>

        <div
            class="item"
            *ngIf="!isStudentExperience"
            (click)="setActiveView(VIEWS.Grades); $event.stopPropagation()"
        >
            <span class="item-title">Grades</span>
            <div class="arrow-right">
                <app-icon [type]="'Arrow-right'"></app-icon>
            </div>
        </div>

        <ng-container *ngIf="!userService.hasStudentExperience() && !isAddonView">
            <div class="item">
                <a
                    (click)="navigateToStudentSite()"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="{{ gtmPrefix + 'standards' }}"
                >
                    Student site
                    <em class="fas fa-external-link-alt ms-2"></em>
                </a>
            </div>
        </ng-container>
    </div>

    <div
        *ngIf="activeViewId === VIEWS.Grades || activeViewId === VIEWS.Subjects"
        @slideInLeft
        class="view"
    >
        <div class="item back-btn" (click)="setActiveView(VIEWS.Initial); $event.stopPropagation()">
            <div class="arrow-left">
                <app-icon [type]="'Arrow-left'"></app-icon>
            </div>
            <span class="item-title">Back</span>
        </div>

        <div class="item subject-title">
            {{ mobileSubjectTitle }}
        </div>

        <!--Subjects Section-->
        <div *ngIf="activeViewId === VIEWS.Subjects">
            <div
                class="item"
                *ngFor="let discipline of disciplines$ | async"
                (click)="
                    setActiveView(VIEWS.Discipline);
                    selectedDiscipline = discipline;
                    $event.stopPropagation()
                "
            >
                <span class="item-title" [ngClass]="disciplineIdColorClasses.get(discipline.id)">{{
                    discipline.title
                }}</span>
                <div class="arrow-right">
                    <app-icon [type]="'Arrow-right'"></app-icon>
                </div>
            </div>
        </div>

        <!--Grades Section-->
        <div class="grades" *ngIf="activeViewId === VIEWS.Grades && !isStudentExperience">
            <div class="item" *ngFor="let grade of gradeName">
                <app-link
                    [appRouterLink]="
                        (isAddonView ? selectedAddOn + '/add-on/grades/' : '/grades/') + grade[0]
                    "
                    class="{{ gtmPrefix + 'grade-band' }}"
                    [attr.data-gtmlabel]="'{{ grade[1] }}'"
                    >{{ grade[0] }}
                </app-link>
            </div>
        </div>
    </div>

    <div *ngIf="activeViewId === VIEWS.Discipline" class="view" @slideInLeft>
        <div
            class="item back-btn"
            (click)="setActiveView(VIEWS.Subjects); $event.stopPropagation()"
        >
            <div class="arrow-left">
                <app-icon [type]="'Arrow-left'"></app-icon>
            </div>
            <span class="item-title">Back</span>
        </div>

        <ng-container *ngIf="selectedDiscipline">
            <div class="item subject-title">
                <a
                    [routerLink]="selectedDiscipline.getBrowseURL()"
                    [ngClass]="disciplineIdColorClasses.get(selectedDiscipline.id)"
                    >{{ mobileSubjectTitle }}</a
                >
            </div>
            <div class="item" *ngFor="let child of getAvailableSubjects(selectedDiscipline)">
                <app-link
                    [appRouterLink]="child.getBrowseURL()"
                    [ngClass]="disciplineIdColorClasses.get(selectedDiscipline.id)"
                    class="{{ gtmPrefix + 'child-title' }}"
                    [attr.data-gtmlabel]="child.title"
                >
                    {{ child.title }}</app-link
                >
            </div>
        </ng-container>
    </div>
</div>
