import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { ClassroomAddOnComponent } from "./log-in-iframe/classroom/classroom-add-on.component"
import { SharedComponentsModule } from "../shared-components/shared-components.module"
import { SchoologyAddOnComponent } from "./log-in-iframe/schoology/schoology-add-on.component"
import { LogInAddOnComponent } from "./log-in-iframe/log-in-add-on.component"
import { AddOnRoutingModule } from "./add-on-routing.module"

@NgModule({
    declarations: [ClassroomAddOnComponent, SchoologyAddOnComponent, LogInAddOnComponent],
    imports: [CommonModule, SharedComponentsModule, AddOnRoutingModule],
})
export class AddOnModule {}
