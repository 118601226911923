import { Component, EventEmitter, Input, Output } from "@angular/core"
import ResourceUtils from "../../../utils/resource-utils"
import { CHNode } from "../../../models/node.model"

@Component({
    selector: "app-subjects-dropdown",
    templateUrl: "./subjects-dropdown.component.html",
    styleUrls: ["./subjects-dropdown.component.scss"],
})
export class SubjectsDropdownComponent {
    @Input()
    subjects: CHNode[] = []

    @Output()
    subjectSelected: EventEmitter<string[] | string> = new EventEmitter<string[] | string>()

    @Output()
    toggleSubjectPanel: EventEmitter<string> = new EventEmitter<string>()
    getSubjectColor = ResourceUtils.getSubjectColor
    isSubjectsPanelOpened: boolean = false

    onSubjectSelected(url: string[] | string): void {
        if (!url.length) return
        this.isSubjectsPanelOpened = false
        this.subjectSelected.emit(Array.isArray(url) ? [...url] : url.split("/"))
    }

    toggleSubjectsPanel(): void {
        this.isSubjectsPanelOpened = !this.isSubjectsPanelOpened
        this.toggleSubjectPanel.emit("subject")
    }

    getAvailableSubjects(discipline) {
        return discipline.children.filter((node): boolean => node.resultsCount > 0)
    }
}
