<div class="search-card-meta" [ngClass]="{ 'justify-content-start': isAddonView }">
    <div class="content-project selenium-card-content-project" *ngIf="contentProject">
        {{ contentProject.title }}
    </div>

    <div
        class="brand selenium-result-item-brand"
        *ngIf="brand && !(contentProject && contentProject.title)"
    >
        <span class="label">{{ brand }}</span>
    </div>

    <div class="grades selenium-result-item-grades" *ngIf="gradesDisplay">
        <span *ngIf="(brand || contentProject) && gradesDisplay" class="separator">|</span>
        <span>Grades</span>&nbsp;<span>{{ gradesDisplay }}</span>
    </div>
    <div class="assign-button-container mt-2">
        <a
            class="support-materials selenium-card-sm-icon {{ gtmPrefix }}"
            [ngClass]="{ 'sm-margin': isAddonView }"
            *ngIf="supportMaterialsCount"
        >
            <app-icon
                ngbTooltip="{{ getToolTipText() }}"
                type="SupportMaterials"
                class="support-materials-icon"
            ></app-icon>
        </a>
        <button
            *ngIf="isAddonView && !!addOnConfig"
            [ngStyle]="{
                'background-color': addOnConfig.customButtonStyle.background,
                color: addOnConfig.customButtonStyle.color,
                border: addOnConfig.customButtonStyle.border
            }"
            (click)="classroomDirectAssign()"
            type="button"
            class="assign-button selenium-assign-card-sm-icon fw-bold ms-4 gtm-assign-resource-button"
        >
            {{ addOnConfig.customButtonStyle.text }}
        </button>
    </div>
</div>
