import { AddOnConfig } from "../models/addOnConfig"
import { STATIC_IMAGES } from "../../models/constants"

export const addOns: AddOnConfig[] = [
    {
        // Classroom Add-On Configuration
        id: "1",
        addOnName: "classroom",
        customLinksAndAPI: { first: "/api/" }, // Placeholder for API details
        resourcePageAssignButton: {
            icon: STATIC_IMAGES.google_classroom_x48_yellow_stroke_icon,
            text: "Assign to class",
            classes: "gtm-share-google-classroom selenium-share-google-classroom",
        },
        searchTopMessage: {
            classes: "search-page",
            text_color: "#2638c4",
            page_regex: "(/classroom/add-on/resource/)",
            day_number: 90,
            mobile_background_color: "#81d4ed1f",
            background_color: "#81d4ed1f",
            call_to_action: "VISIT THE FULL SITE",
            link: "/",
            link_target: "_blank",
            title: "Bring the world to your Google Classroom",
            text:
                "PBS LearningMedia has curated FREE curriculum-aligned videos and a wealth of high-quality educational media for you and your students. Visit our full experience to see our full suite of lesson plans, curated collections, and professional development to enrich your classroom.",
        },
        customButtonStyle: {
            border: "2px #fed636 solid",
            background: "#44a464",
            color: "white",
            text: "Assign",
        },
    },
    {
        // Schoology Add-On Configuration
        id: "2", // Unique identifier for Schoology
        addOnName: "schoology",
        customLinksAndAPI: { first: "/api/" }, // Placeholder for API details
        resourcePageAssignButton: {
            icon: STATIC_IMAGES.schoology_icon,
            text: "Create Schoology Assignment",
            classes: "gtm-share-schoology selenium-share-schoology",
        },
        searchTopMessage: {
            classes: "search-page",
            text_color: "#fff",
            page_regex: "(/schoology/add-on/resource/)",
            day_number: 90,
            mobile_background_color: "#3f8dbbcf",
            background_color: "#3f8dbbcf",
            call_to_action: "VISIT THE FULL SITE",
            link: "/",
            link_target: "_blank",
            title: "Bring the world to Schoology",
            text:
                "PBS LearningMedia has curated FREE curriculum-aligned videos and a wealth of high-quality educational media for you and your students. Visit our full experience to see our full suite of lesson plans, curated collections, and professional development to enrich Schoology.",
        },
        customButtonStyle: {
            border: "2px #b7b8d7 solid",
            background: "#228dce",
            color: "white",
            text: "Assign",
        },
    },
]
