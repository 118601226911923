import { Component, OnInit } from "@angular/core"
import { AlertData } from "../../models/alertData.model"
import { WindowSizeService } from "../../services/window-size.service"
import { CookiesService } from "../../services/cookies.service"
import { UserService } from "../../services/user.service"
import { AddOnViewService } from "../../services/add-on-view.service"

export enum AlertBarCookie {
    HideAlert = "hideAlert",
}

@Component({
    selector: "app-alert-bar",
    templateUrl: "./alert-bar.component.html",
    styleUrls: ["./alert-bar.component.scss"],
})
export class AlertBarComponent implements OnInit {
    public showAlert: boolean
    public alertData: AlertData

    private readonly studentCookieKey: string = "showAlertStudent"
    private readonly teacherCookieKey: string = "showAlertTeacher"

    constructor(
        public windowSize: WindowSizeService,
        private cookieService: CookiesService,
        private userService: UserService,
        private addOnService: AddOnViewService
    ) {}

    private get cookieKey(): string {
        return this.userService.hasStudentExperience()
            ? this.studentCookieKey
            : this.teacherCookieKey
    }

    public ngOnInit() {
        this.loadAlertData()
        this.setComponentVisibility()
    }

    public close() {
        this.showAlert = false
        this.cookieService.setCookie(
            this.cookieKey,
            AlertBarCookie.HideAlert,
            this.alertData.day_number
        )
    }

    private loadAlertData() {
        if (this.userService.hasStudentExperience()) {
            this.alertData = this.userService.studentAlertData
        } else {
            this.alertData = this.addOnService.isAddonView
                ? this.addOnService.addOnAlertData
                : this.userService.teacherAlertData
        }
    }

    private setComponentVisibility() {
        const alertBarCookie = this.cookieService.getCookie(this.cookieKey)
        const currentPathNameNotExcluded =
            this.alertData &&
            this.alertData.page_regex &&
            !new RegExp(this.alertData.page_regex).test(window.location.pathname)
        const userExpressedIntentionToHide =
            alertBarCookie && alertBarCookie.value === AlertBarCookie.HideAlert

        this.showAlert =
            this.alertData &&
            currentPathNameNotExcluded &&
            (!userExpressedIntentionToHide || this.addOnService.isAddonView)
    }
}
