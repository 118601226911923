import { PBSLM_TITLE } from "./constants"
import Utils from "../utils/utils"

export class BaseNode {
    protected constructor(
        public id: number,
        public title: string,
        public type: string = "",
        public parents: BaseNode[] = [],
        public children: BaseNode[] = []
    ) {}

    public static fromObject(obj, parents: BaseNode[] = []): BaseNode {
        const { id, title, children = [], type } = obj
        const node: BaseNode = new BaseNode(id, title, type, parents)

        if (children.length) {
            const childParents: BaseNode[] = parents.concat(node)
            node.children = children.map((child) => BaseNode.fromObject(child, childParents))
        }

        return node
    }
}

export class CHNode extends BaseNode {
    private static nodesByID: Record<number, CHNode> = {}
    private static nodesBySlug: Record<string, CHNode> = {}
    public static nodesByTitle: Record<string, CHNode> = {}

    private constructor(
        public id: number,
        public title: string,
        public resultsCount: number,
        public banner: string = "",
        public description: string = "",
        public posterImage: string = "",
        public posterImageAltText: string = "",
        public slug: string = "",
        public parents: CHNode[] = [],
        public children: CHNode[] = [],
        public metaTitle: string = "",
        public type: string = "subject"
    ) {
        super(id, title, type, parents)
        this.posterImage = Utils.escapePosterImage(this.posterImage)
    }

    public static empty(): CHNode {
        return new CHNode(0, "", 0)
    }

    public static fromObject(obj, parents: CHNode[] = []): CHNode {
        const {
            id,
            title,
            results_count,
            banner = "",
            description = "",
            poster_image = "",
            poster_image_alt_text = "",
            slug = "",
            children = [],
        } = obj
        const node: CHNode = new CHNode(
            id,
            title,
            results_count,
            banner,
            description,
            poster_image,
            poster_image_alt_text,
            slug,
            parents
        )

        // Optimization. The title is the same
        node.metaTitle = obj.meta_title || `${title} | ${PBSLM_TITLE}`
        if (!slug && parents.length) {
            // Optimization. The slug should be calculated based on the parent
            const lastParent: CHNode = parents[parents.length - 1]
            node.slug = `${this.cleanSlug(lastParent.slug)}${this.slugify(node.title)}/`
        }

        if (children.length) {
            const childParents: CHNode[] = parents.concat(node)
            node.children = children.map((child) => CHNode.fromObject(child, childParents))
        }

        CHNode.addToFlatLists(node)
        return node
    }

    private static addToFlatLists(node: CHNode) {
        CHNode.nodesByID[node.id] = node
        CHNode.nodesBySlug[node.slug] = node
        CHNode.nodesByTitle[node.title.toLowerCase()] = node
    }

    public static byID(id: number): CHNode {
        return CHNode.nodesByID[id]
    }

    public static bySlug(slug: string): CHNode {
        return CHNode.nodesBySlug[slug]
    }

    public static byTitle(title: string): CHNode {
        return CHNode.nodesByTitle[title]
    }

    private static slugify(title: string): string {
        return title
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^-a-z0-9/_]/g, "")
    }

    public static cleanSlug(slug: string): string {
        if (!slug.startsWith("/")) {
            slug = `/${slug}`
        }
        if (!slug.endsWith("/")) {
            slug = `${slug}/`
        }
        return slug
    }

    public static getBrowseURLForSlug(slug: string): string {
        let isAddonView: boolean = Utils.isAddOnExperienceLink(window.location.pathname, "/add-on/")
        let selectedAddOn: string = sessionStorage.getItem("selected-add-on")
        return (
            (isAddonView && !!selectedAddOn ? `/${selectedAddOn}/add-on/subjects` : "/subjects") +
            this.cleanSlug(slug)
        )
    }

    public getBrowseURL(): string {
        return CHNode.getBrowseURLForSlug(this.slug)
    }

    public getDepth(): number {
        const parentsLength: number = this.parents.length
        return parentsLength ? parentsLength + 1 : 1
    }

    private getNodeFromLevel(level: number): CHNode {
        const parentsLength: number = this.parents.length
        if (parentsLength < level) {
            return null
        }
        if (parentsLength === level) {
            return this
        }
        return this.parents[level]
    }

    public getDiscipline(): CHNode {
        return this.getNodeFromLevel(0)
    }

    public getSubject(): CHNode {
        return this.getNodeFromLevel(1)
    }

    public getTopic(): CHNode {
        return this.getNodeFromLevel(2)
    }

    public getSubTopic(): CHNode {
        return this.getNodeFromLevel(3)
    }

    public getTopParents(limit: number = 2, includeSelf: boolean = true): CHNode[] {
        const availableNodes: CHNode[] = this.parents.slice()
        if (includeSelf) {
            availableNodes.push(this)
        }
        return availableNodes.slice(0, limit)
    }

    public isLoaded(): boolean {
        return !!(this.slug && this.title)
    }
}
