import { DashboardMenuPagesWhiteList, GradeBandEnum, ORDERED_GRADE_KEYS } from "../models/constants"

declare var window: any

export default class Utils {
    public static alertConfiguration = {
        timeOut: 5000,
        showProgressBar: false,
        pauseOnHover: false,
        clickToClose: true,
    }

    public static get addOnName() {
        return window.location.pathname.split("/")[1]
    }

    public static convertToCamelcase(str: string): string {
        return str.replace(/_([a-z])/g, (all, char) => char.toUpperCase())
    }

    public static isPreviewMode(): boolean {
        return window.location.pathname.includes("/preview/")
    }

    public static addTargetToHTMLLinks(html: string): string {
        return html.includes("<a") ? html.replace("<a", "<a target='_blank'") : html
    }

    public static serializeQueryParams(params: any): string {
        return Object.keys(params)
            .map((key: string) => {
                const values = Array.isArray(params[key]) ? params[key] : [params[key]]
                return values
                    .map(
                        (value): string => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                    )
                    .join("&")
            })
            .join("&")
    }

    public static extractUrlQueryParams(url: string): { [key: string]: string | string[] } {
        const params: { [key: string]: string | string[] } = {}

        const queryString: string = new URL(url).search.slice(1)
        if (!queryString) {
            return params
        }

        const paramPairs: string[] = queryString.split("&")
        paramPairs.forEach((queryParam: string) => {
            const [key, value] = queryParam.split("=")
            const decodedValue: string = decodeURIComponent(value)
            if (params[key]) {
                if (Array.isArray(params[key])) {
                    // @ts-ignore
                    params[key].push(decodedValue)
                } else {
                    params[key] = [params[key] as string, decodedValue]
                }
            } else {
                params[key] = decodedValue
            }
        })

        return params
    }

    public static isTheSamePage(previousUrl: string, currentUrl: string): boolean {
        const previousURL: string = this.extractUrlWithoutQueryParams(previousUrl)
        const currentURL: string = this.extractUrlWithoutQueryParams(currentUrl)
        return (
            previousURL === currentURL ||
            previousURL.includes(currentURL) ||
            currentURL.includes(previousURL)
        )
    }
    public static extractUrlWithoutQueryParams(url: string): string {
        return url.indexOf("?") > -1 ? url.substring(0, url.indexOf("?")) : url
    }

    public static wasPageReloaded(): boolean {
        return performance.navigation.type === performance.navigation.TYPE_RELOAD
    }

    public static extractUrlPathname(url: string): string {
        return new URL(url).pathname
    }

    //userService in some cases is not instantiated and the studentExperience state is wrong
    public static hasStudentExperience(): boolean {
        //regex checks if pathname starts with "/student" and has 0 or more chars after
        return (
            this.testIfPathnameFitRegEx("^/student.{0,}$") ||
            window.location.search.includes("student=")
        )
    }

    public static testIfPathnameFitRegEx(regExp: string): boolean {
        return new RegExp(regExp).test(window.location.pathname)
    }

    public static concatUrlParams(params: { [key: string]: string }): string {
        return Object.keys(params)
            .map((key: string): string => `${key}=${params[key]}`)
            .join("&")
    }

    public static capitalizeWords(str: string): string {
        return str.replace(/\b\w/g, function (char: string) {
            return char.toUpperCase()
        })
    }

    public static shareLinkToGC(): void {
        if (!window.location) return
        const googleClassWindow = window.open(
            "",
            "_blank",
            "width=640, height=450,toolbar=no, scrollbars=yes"
        )
        googleClassWindow.location.href =
            "https://classroom.google.com/share?url=" + window.location
    }

    public static isSegmentContainedInUrl(
        segmentToCheck: string,
        routeToBeChecked?: string,
        splitter?: string
    ): boolean {
        if (routeToBeChecked) return routeToBeChecked.split(splitter).includes(segmentToCheck)
        return window.location.pathname.split("/").includes(segmentToCheck)
    }

    public static isAddOnExperienceLink(routeToBeChecked: string, segmentToCheck: string): boolean {
        return routeToBeChecked.includes(segmentToCheck)
    }

    public static removeHtmlTags(text: string = ""): string {
        return String(text)
            .replace(/<!--[\s\S]*?(?=-->)-->/gm, "") // Remove comments
            .replace(/<[^>]+>/gm, "")
            .replace(/(\r\n|\n|\r)/gm, "")
    }

    public static escapePosterImage(posterImage: any) {
        if (this.isArray(posterImage)) {
            return posterImage.map((item) => {
                item.url = Utils.escapePosterImage(item.url)
                return item
            })
        }
        if (this.isString(posterImage)) {
            return this.urlEscapeQuotes(posterImage)
        }
        if (posterImage && posterImage.url) {
            posterImage.url = this.urlEscapeQuotes(posterImage.url)
        }
        return posterImage
    }

    public static checkIfDashboardMenuAllowed(): boolean {
        for (let link of DashboardMenuPagesWhiteList) {
            if (window.location.pathname.includes(link)) {
                return true
            }
        }
        return false
    }

    public static urlEscapeQuotes(url: string): string {
        if (url) {
            return url.replace(/'/g, "\\'")
        }
        return null
    }

    public static isArray(arr: any) {
        return Object.prototype.toString.call(arr) === "[object Array]"
    }

    public static isObject(obj: any): boolean {
        return Object.prototype.toString.call(obj) === "[object Object]"
    }

    public static isString(str: string): boolean {
        return Object.prototype.toString.call(str) === "[object String]"
    }

    public static isEmpty(value: any): boolean {
        if (typeof value === "number") return false
        else if (typeof value === "string") return value.trim().length === 0
        else if (Array.isArray(value)) return value.length === 0
        else if (typeof value === "object") return value == null || Object.keys(value).length === 0
        else if (typeof value === "boolean") return false
        else return !value
    }

    public static isNullOrUndefined(value: any): boolean {
        return value == null
    }

    public static copyLinkByElementId(elemId): void {
        const elem: HTMLInputElement = document.getElementById(elemId) as HTMLInputElement
        elem.select()
        document.execCommand("copy")
    }

    public static removeStyling(htmlString) {
        if (htmlString) {
            // remove all styles from the html received as string (for example fields with TinyMCE widgets)
            htmlString = htmlString.replace(/[^ <>]*style="[^<>]*"/gm, "")
            // a whitelist of allowed tags
            const whitelist: string[] = [
                "ul",
                "ol",
                "li",
                "sub",
                "sup",
                "table",
                "tbody",
                "tr",
                "td",
                "p",
                "span",
            ]
            const regex: RegExp = new RegExp("</?(?!" + whitelist.join("|") + ")[^>/]+>", "gm")
            return htmlString !== null ? htmlString.replace(regex, "") : ""
        }
        return htmlString
    }

    public static getCurrentYear(): number {
        return new Date().getFullYear()
    }

    public static isAssignmentCode(code): boolean {
        if (!code) {
            return false
        }
        const firstCharacter = code[0]
        return firstCharacter >= "a" && firstCharacter <= "z"
    }

    public static getITSImageIfExists(
        images: any[],
        type: string,
        width?: number,
        height?: number
    ) {
        if (!images) {
            return null
        }
        const posterImage = images.find((image): boolean => image.type === type)
        if (!posterImage) {
            return null
        }
        if (width && height && (type === "original" || type === "original-square")) {
            return `${posterImage.url}?resize=${width}x${height}&format=${
                Utils.shouldNotUseWebp() ? "jpg" : "webp"
            }`
        }
        return posterImage.url
    }

    public static openInNewTab(url: string): void {
        const win = window.open(url, "_blank", "noopener")
        if (win) {
            win.focus()
        }
    }

    public static generateUniqueId(): string {
        return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase()
    }

    public static copyTextToClipboard(text: string): void {
        const selBox: HTMLTextAreaElement = document.createElement("textarea")
        selBox.style.position = "fixed"
        selBox.style.left = "0"
        selBox.style.top = "0"
        selBox.style.opacity = "0"
        selBox.value = text
        document.body.appendChild(selBox)

        // Determine if user's device is an iPad or an iPhone
        if (navigator.userAgent.match(/iPad|iPhone/i)) {
            const range: Range = document.createRange()
            range.selectNodeContents(selBox)
            const selection = window.getSelection()
            selection.removeAllRanges()
            selection.addRange(range)
            selBox.setSelectionRange(0, 999999)
        } else {
            selBox.focus()
            selBox.select()
        }

        document.execCommand("copy")
        document.body.removeChild(selBox)
    }

    public static buildCssSelector(text: string): string {
        return text
            .toLowerCase()
            .match(/[^_\W]+/g)
            .join("-")
    }

    public static resize(callback: any, time: number = 500): void {
        let resizeId
        window.addEventListener("resize", () => {
            clearTimeout(resizeId)
            resizeId = setTimeout(callback, time)
        })
    }

    // Info: The measurement unit is pixel (px).
    public static isScreenWidthLarger(screenWidth: number): boolean {
        return window.innerWidth > screenWidth
    }

    public static isIEBrowser() {
        return window.document.hasOwnProperty("documentMode")
    }

    public static isEdgeBrowser(): boolean {
        if (!Utils.isIEBrowser() && window.hasOwnProperty("StyleMedia")) {
            return true
        }
        return false
    }

    public static isSafari() {
        let userAgent = window.navigator.userAgent
        let issafari: boolean = /^((?!chrome|android).)*safari/i.test(userAgent)
        return userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || issafari
    }

    public static isFirefox(): boolean {
        return navigator.userAgent.indexOf("Firefox") != -1
    }

    public static flattenErrors(errors: Record<string, any>, keyPrefix = null): [string, string][] {
        return Object.entries(errors).reduce((acc: any[], [key, val]) => {
            let fullKey: string = keyPrefix ? `${keyPrefix} - ${key}` : key
            if (key === "non_field_errors") {
                fullKey = "Error"
            }
            Array.from(val).flatMap((err) => {
                if (typeof err === "string") {
                    acc.push([this.capitalizeWords(fullKey.replace("_", " ")), err])
                } else {
                    acc = [...acc, ...Utils.flattenErrors(err, fullKey)]
                }
            })
            return acc
        }, [])
    }

    public static concatenateFlattenErrors(errors: Record<string, any>): string[] {
        let message: [string, string][] = Utils.flattenErrors(errors)
        let result: string[] = []
        for (let key in message) {
            result.push(message[key][0] + " - " + message[key][1])
        }
        return result
    }

    /**
     * this includes if it's Safari, IE or Edge
     */
    public static shouldNotUseWebp() {
        return window.PBSLM.browser.ie || window.PBSLM.browser.edge || window.PBSLM.browser.safari
    }

    public static sleep = (ms: number) =>
        new Promise<void>((resolve) => setTimeout(() => resolve(), ms))

    public static shuffleArray(array): void {
        for (let i: number = array.length - 1; i > 0; i--) {
            const j: number = Math.floor(Math.random() * (i + 1))
            ;[array[i], array[j]] = [array[j], array[i]]
        }
    }

    public static getCompareFnByKey(key: string) {
        return (a: Object, b: Object): number => {
            const normalizedA = a[key].toUpperCase()
            const normalizedB = b[key].toUpperCase()

            if (normalizedA < normalizedB) {
                return -1
            }
            if (normalizedA > normalizedB) {
                return 1
            }

            return 0
        }
    }

    public static scrollIntoView(element: HTMLElement, offset: number = 0): void {
        document.documentElement.scrollTop = element.offsetTop + offset
    }

    /**
     * Get an array with randomized items and positions
     * @param arr
     * @param n
     */
    public static getRandomItems(arr, n): any[] {
        let len = arr.length
        const taken: any[] = new Array(len)
        const result: any[] = new Array(n)

        if (n > len) throw new RangeError("getRandomItems: more items taken than available")
        while (n--) {
            const x: number = Math.floor(Math.random() * len)
            result[n] = arr[x in taken ? taken[x] : x]
            taken[x] = --len in taken ? taken[len] : len
        }
        return result
    }

    public static isCurrentURLForStudents() {
        return (
            window.location.pathname.startsWith("/student/") ||
            window.location.search.includes("student=true")
        )
    }

    /**
     * Returns a boolean representing if a file name has the right extension
     * @param extType - extension to check for ex: png
     * @param validExtensions - list of valid extensions
     */
    public static hasFileValidExtension(extType: string, validExtensions: string[]): boolean {
        if (!extType || !validExtensions.length) return
        return validExtensions.map((ext: string) => ext.toUpperCase()).indexOf(extType) >= 0
    }

    /**
     * Open a pop-up window and returns a WindowProxy object
     * @param url
     * @param target
     * @param features
     */
    public static openPopUp(
        url: string | URL,
        target: string = "popUpWindow",
        features?: string
    ): WindowProxy | null {
        const windowFeatures: string =
            features ||
            "height=500, width=500" +
                "left=100, top=100" +
                "resizable=no, scrollbars=yes" +
                "toolbar=yes, menubar=no" +
                "location=yes, directories=no" +
                "status=yes"

        return window.open(url, target, windowFeatures)
    }

    public static isLocalStorageAvailable(): boolean {
        const key = "pbsLocalStorage"
        try {
            localStorage.setItem(key, key)
            localStorage.removeItem(key)
            return true
        } catch (e) {
            return false
        }
    }

    public static areCookiesEnabled(): boolean {
        let cookieEnabled: boolean = navigator.cookieEnabled
        if (!cookieEnabled) {
            document.cookie = "pbsTestCookie"
            cookieEnabled = document.cookie.indexOf("pbsTestCookie") != -1
        }
        return cookieEnabled
    }

    /**
     * Check if two array have the same items
     * @param firstArray
     * @param secondArray
     * @private
     */
    public static areEqual(firstArray: any[], secondArray: any[]): boolean {
        return (
            firstArray.length === secondArray.length &&
            firstArray.every((element) => secondArray.includes(element))
        )
    }
}
