import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import Utils from "../../../utils/utils"
import { STATIC_IMAGES } from "../../../models/constants"

@Component({
    selector: "app-login-classroom",
    templateUrl: "./classroom-add-on.component.html",
    styleUrls: ["./classroom-add-on.component.scss"],
})
export class ClassroomAddOnComponent implements OnInit {
    areCookiesEnabled: boolean = false
    isSafariBrowser: boolean = false
    isStudentAccountSelected: boolean = false
    googleImg: string = STATIC_IMAGES.auth_google
    PBSLMLogo: string = STATIC_IMAGES.pbslm_logo
    loginMessage: string =
        "Please sign in with your Google Classroom account in order to access PBS LearningMedia’s free teaching resources."

    constructor(private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.isSafariBrowser = Utils.isSafari()
        this.areCookiesEnabled = Utils.areCookiesEnabled()

        const queryParam: string = this.activatedRoute.snapshot.queryParamMap.get("logOut")
        if (!queryParam) return
        this.isStudentAccountSelected = true
        this.loginMessage =
            "Your account does not have permission to access this service. Sign out and try signing in with a Google Classroom teacher account."
    }

    logOut() {
        //TODO: Check if this works with classroom !!!
        window.location.href = decodeURIComponent("/logout/?next=/classroom/add-on/search/")
    }

    openLoginPopUp(): void {
        //TODO: Check if this works with classroom !!!
        const loginUrl: string =
            "/profile/login-national/google-oauth2/?next=/classroom/add-on/close-login-popup/"
        Utils.openPopUp(loginUrl)
    }
}
