import { debounceTime, distinctUntilChanged, map, merge, switchMap } from "rxjs/operators"
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { NgForm } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap"
import { combineLatest, Observable, Subject, Subscription } from "rxjs"
import { BrowsingService } from "../../services/browsing.service"
import { FacetingService } from "../../services/faceting.service"
import { GTMService } from "../../services/gtm.service"
import { SearchingService } from "../../services/searching.service"
import { UserService } from "../../services/user.service"
import { AddOnViewService } from "../../services/add-on-view.service"
import { StudentGateService } from "../../services/student-gate.service"
import Utils from "../../utils/utils"
import { PreviewService } from "../../services/preview.service"

@Component({
    selector: "app-external-search-input",
    templateUrl: "./external-search-input.component.html",
    styleUrls: ["./external-search-input.component.scss"],
})
export class ExternalSearchInputComponent implements OnInit, OnDestroy {
    @ViewChild("formObj", { static: true }) public form: NgForm
    @ViewChild("submitBtn") public submitBtn: ElementRef<HTMLElement>
    @ViewChild("formInput") public formInput: ElementRef<HTMLElement>

    $focus: Subject<string> = new Subject<string>()
    $click: Subject<string> = new Subject<string>()
    @Input() placeholder: string = ""
    @Input() buttonText: string = "" // if left empty there will be no button, it will show only the icon
    @Input() type: string = "for-browse" // for-header, for-browse, for-error depending on type it will add another style
    @Input() disableAutocomplete: boolean = false
    _pressedEnter: boolean = false

    subscriptions: Subscription[] = []

    constructor(
        public router: Router,
        public facetingService: FacetingService,
        public gtmService: GTMService,
        private searchingService: SearchingService,
        private browsingService: BrowsingService,
        private addOnService: AddOnViewService,
        private userService: UserService,
        private gradeGate: StudentGateService,
        protected previewService: PreviewService
    ) {}

    public ngOnInit() {
        this.form.resetForm()

        // TODO this is not working as expected, we need to fix it, should be in sync with app-search-input ?!
        // this.subscriptions.push(
        //     this.route.queryParams.subscribe((queryParams: Params) => {
        //         if (queryParams.hasOwnProperty("q")) {
        //             setTimeout(() => {
        //                 this.form.setValue({ q: queryParams.q })
        //             })
        //         }
        //     })
        // )
    }

    public onSearch(form: NgForm) {
        const queryParams = form.value
        queryParams.q = !!queryParams?.q?.length ? queryParams.q : ""
        if (this.userService.hasStudentExperience()) {
            queryParams.student = true
            if (this.gradeGate.selectedGradeValue) {
                queryParams.selected_facet = "grades:" + this.gradeGate.selectedGradeValue
            }
        }

        this.router
            .navigate(
                [
                    this.addOnService.isAddonView
                        ? this.addOnService.selectedAddOn + "/add-on/search"
                        : "search",
                ],
                {
                    queryParams,
                }
            )
            .then((_) => {
                this.form.resetForm()
                this.formInput.nativeElement.blur()
                this.submitBtn.nativeElement.blur()
            })

        this._pressedEnter = true
        this.gtmService.pushEvent("external-search")
    }

    public resetPressedEnter(term: string) {
        if (!term) return
        this._pressedEnter = false
    }

    public onSelectItem(form: NgForm, event: NgbTypeaheadSelectItemEvent) {
        const queryParams = { q: event.item.title, selected_facet: [] }
        if (event.item.subject) {
            queryParams.selected_facet.push(
                this.facetingService.makeSubjectQueryParam(event.item.subject.id)
            )
        }
        if (event.item.grade) {
            queryParams.selected_facet.push(
                this.facetingService.makeGradeQueryParam(event.item.grade)
            )
        }
        this.router
            .navigate(
                [
                    this.addOnService.isAddonView
                        ? this.addOnService.selectedAddOn + "/add-on/search"
                        : "search",
                ],
                {
                    queryParams,
                }
            )
            .then((_) => {
                this.form.resetForm()
                this.formInput.nativeElement.blur()
                this.submitBtn.nativeElement.blur()
            })

        const eventName =
            form.value.q === event.item.title ? "external-search-enter" : "search-autocomplete"
        this.gtmService.pushEvent(eventName)
    }

    public onAutocomplete = ($text: Observable<string>) => {
        return $text.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            merge(this.$focus),
            merge(this.$click),
            switchMap((term) => this.prepareAutocompleteResults(term))
        )
    }

    private prepareAutocompleteResults(term) {
        if (this.disableAutocomplete) return []

        return combineLatest([
            this.searchingService.getAutocompleteResults(term),
            this.browsingService.autocompleteSubjects,
        ]).pipe(
            map((data: any) => {
                if (this._pressedEnter) {
                    return []
                }
                const response = data[0]
                let suggestions = []
                suggestions = suggestions.concat(this.getSmartSuggestions(term, data[1]))
                if (response.length) {
                    const responses = response.objects.map((result) => ({
                        ...result,
                        displayTitle: result.title,
                    }))
                    suggestions = suggestions.concat(responses)
                }
                return suggestions
            })
        )
    }

    private removeFromTitle(part, title) {
        let cleanedTitle = title.replace(part, "").replace(/\s\s+/g, " ")
        if (!cleanedTitle.length) {
            return cleanedTitle
        }
        if (cleanedTitle[0] == " ") {
            cleanedTitle = cleanedTitle.slice(1)
        }
        if (cleanedTitle[cleanedTitle.length - 1] == " ") {
            cleanedTitle = cleanedTitle.slice(0, -1)
        }
        return cleanedTitle
    }

    private getGradeSuggestion(searchPhrase) {
        let suggestedGrade = null
        const prekMatch = /(\s|^)(prek)(\s|$)/i.exec(searchPhrase)
        if (prekMatch) {
            searchPhrase = this.removeFromTitle(prekMatch[2], searchPhrase)
            return ["PreK", searchPhrase]
        }
        const kMatch = /(\s|^)(k( grade|)|kindergarten)(\s|$)/i.exec(searchPhrase)
        if (kMatch) {
            searchPhrase = this.removeFromTitle(kMatch[2], searchPhrase)
            return ["K", searchPhrase]
        }
        const plusMatch = /(\s|^)(13\+(th|)( grade|))(\s|$)/i.exec(searchPhrase)
        if (plusMatch) {
            searchPhrase = this.removeFromTitle(plusMatch[2], searchPhrase)
            return ["13+", searchPhrase]
        }
        const gradeMatch = /(\s|^)((\d)(th|nd|rd|st|)( grade|))(\s|$)/i.exec(searchPhrase)
        if (gradeMatch) {
            const grade = +gradeMatch[3]
            if (grade >= 1 && grade <= 13) {
                suggestedGrade = grade
                searchPhrase = this.removeFromTitle(gradeMatch[2], searchPhrase)
            }
        }
        return [suggestedGrade, searchPhrase]
    }

    private getSmartSuggestions(searchPhrase, nodesByTitle) {
        const gradeData = this.getGradeSuggestion(searchPhrase)
        const gradeSuggestion = gradeData[0]
        searchPhrase = gradeData[1]
        const terms = searchPhrase.split(" ")
        if (terms.length < 1) {
            return []
        }
        const suggestions = []
        if (gradeSuggestion) {
            suggestions.push({
                displayTitle: searchPhrase,
                title: searchPhrase,
                grade: gradeSuggestion,
            })
        }
        terms.forEach((term) => {
            const cleanedTerm = term.toLowerCase()
            if (cleanedTerm in nodesByTitle) {
                const title = this.removeFromTitle(term, searchPhrase)
                suggestions.push({
                    displayTitle: title ? title : "All",
                    title,
                    subject: nodesByTitle[cleanedTerm],
                    grade: gradeSuggestion,
                })
            }
        })
        if (suggestions.length > 0) {
            suggestions[suggestions.length - 1].isLastSmart = true
        }
        return suggestions
    }

    public ngOnDestroy() {
        this.subscriptions.map((subscription) => subscription.unsubscribe())
    }

    public formatMatches = (value: any) => value.title || ""
}
