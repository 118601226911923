import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { CardMetaComponent } from "../card-meta.component"
import { AddOnViewService } from "../../../../../services/add-on-view.service"
import { ReplaySubject } from "rxjs"
import { takeUntil } from "rxjs/operators"
import { AddOnConfig } from "../../../../../add-on/models/addOnConfig"
import { environment } from "../../../../../../environments/environment"

@Component({
    selector: "app-search-card-meta",
    templateUrl: "./search-card-meta.component.html",
    styleUrls: ["./search-card-meta.component.scss"],
})
export class SearchCardMetaComponent extends CardMetaComponent implements OnInit, OnDestroy {
    @Input() gtmDataCardNumber: number
    @Input() gtmPrefix: string
    @Input() resourceCode: string
    addOnConfig: AddOnConfig
    isAddonView: boolean
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1)

    constructor(private addOnService: AddOnViewService) {
        super()
    }

    ngOnInit() {
        this.isAddonView = this.addOnService.isAddonView
        this.addOnConfig = this.addOnService.selectedAddOnConfig
    }

    ngOnDestroy() {
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }

    classroomDirectAssign() {
        if ((this.addOnConfig.addOnName = "schoology")) {
            this.addOnService
                .importResource(this.resourceCode)
                .pipe(takeUntil(this.destroyed$))
                .subscribe()
        } else {
            this.addOnService
                .createAttachment(this.resourceCode)
                .pipe(takeUntil(this.destroyed$))
                .subscribe()
        }
    }
}
